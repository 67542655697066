<template>
  <div class="form-data item-chat" :class="{'chat-mobile':isMobile}">
    <img :src="item.avatar || NoAvatar" class="avatar rounded-circle mr-2" alt="avatar">
    <div class="content">
      <div class="font-weight-bold name">{{item.name}}
        <img v-if="item.is_teacher" src="~/mlearn/icons/comment/is-guru.svg" class="mr-2" />
        <span class="time">{{item.created_at | parseTime('{h}:{i} {d}/{m}/{y}') }}</span>
      </div>
      <div class="content text-break" v-html="item.content"></div>
      <div class="text-break" v-html="$helper.checkUrl(item.message)"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
export default {
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      NoAvatar
    }
  },
  computed: {
    ...mapState([
      'isMobile'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .item-chat{
    &:last-child{
      padding-bottom: 20px;
      @media (max-width:576px) {
        padding-bottom: 10px;
      }
    }
    .avatar{
      width: 45px;
      height: 45px;
      @media (max-width:576px) {
        width: 30px;
        height: 30px;
      }
    }
    .name{
      font-weight: 500;
      font-size: 16px;
      @media (max-width:576px) {
        font-size: 14px;
      }
    }
    .time{
      font-size: 12px;
      font-weight: normal;
      color: #65676B;
    }
    .content{
      font-size: 16px;
      @media (max-width:576px) {
        font-size: 14px;
      }
    }
  }
</style>
