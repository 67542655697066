<template>
  <div @click="handleChatAi" v-if="routerName !== 'chat-ai'" :class="routerName === 'tra-loi-slug' ? 'chat-ai-slug pointer' : 'chat-ai pointer'">
    <img src="~/mlearn/icons/home/chat-ai.png" alt="chat-ai" class="chat-img"/>
    <img src="~/mlearn/icons/home/chat-ai-icon.png" alt="chat-ai" class="chat-img-mb"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ChatAi',
  data () {
    return {
      routerName: ''
    }
  },
  computed: {
    ...mapState([
      'authenticated'
    ])
  },
  mounted () {
    this.routerName = this.$router.history.current.name
  },
  methods: {
    handleChatAi () {
      if (this.authenticated) {
        this.$router.push('/chat-ai')
      } else {
        this.$bvModal.show('modal-login')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-ai {
  position: fixed;
  bottom: 35px;
  right: 95px;
}

.chat-img-mb {
  display: none;
}

@media(max-width: 992px) {
  .chat-img {
    display: none;
  }
  .chat-ai {
    bottom: 30px;
    right: 20px;
  }
  .chat-img-mb {
    display: block;
    width: 40px;
    height: 40px;
  }
}

.chat-img {
  width: 150px;
  height: 65px;
}

.chat-ai-slug {
  bottom: 575px;
  right: 100px;
}
</style>
